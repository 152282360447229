
    .text-center {
        text-align: center;
    }
    .storeDecorate-container {
        min-height: calc(100vh - 120px);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .el-tab-pane {
            padding: 20px 20px 50px;
            min-height: calc(100vh - 166px); //
            box-sizing: border-box; //
            display: flex;
            flex-direction: column;
        }
        .operate-btn {
            display: flex;
            justify-content: space-between;
        }
    }
    .category-table {
        width: 100%;
        /*border: 1px solid #eee;*/
        height: 1%; //
        flex: 1; //
        .line {
            display: flex;
            align-items: center;
            /*border-top: 1px solid #eee;*/
            border-left: 1px solid #eee; //
            border-right: 1px solid #eee; //
            border-bottom: 1px solid #eee; //
            &.head {
                height: 46px;
                background: #E5E5E5;
            }
            &.body {
                height: 60px;
            }
            .td {
                width: 25%;
                padding: 0 10px;
                .down {
                    cursor: pointer;
                    color: #7D7D7D;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    margin-right: 4px;
                    &.current {
                        transform: rotate(90deg);
                    }
                }
                .placeIcon {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }
                .place24 {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                }
            }
            &:hover {
                background: #E5E5E5;
            }
        }
        .move-btn {
            width: 24px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            display: inline-block;
            font-size: 16px;
            color: #7D7D7D;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: #FD4446;
            }
        }
        .category-sort {
            /deep/ .el-input__inner {
                text-align: center;
                padding: 0;
            }
        }
    }
    .no-data {
        text-align: center;
        line-height: 60px;
        color: #999;
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }
    .storeSign-btn {
        margin-top: 20px;
        text-align: center;
    }
